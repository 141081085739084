import { createTheme } from '@mui/material/styles';
import palette from './palette';

const theme = createTheme({
  typography: {
    fontFamily: [
      'DM Sans',
      'Montserrat', 
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto', 
      'Helvetica', 
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: palette
})

export default theme;