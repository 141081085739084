import React, { ReactComponentElement } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

function GlobalProviders( props:React.PropsWithChildren ) {
  return(
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID || ''}
      authorizationParams={{
        redirect_uri: window.location.origin + '/auth',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}    
    >
      { props.children }
    </Auth0Provider>
  );
};

export default GlobalProviders;