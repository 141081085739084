import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import theme from './Theme/theme';
import GlobalProviders from './GlobalProviders';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import Auth from './Views/Auth/Auth';

const App = withAuthenticationRequired(React.lazy(() => import('./App')));
const AppSettings = withAuthenticationRequired(React.lazy(() => import('./AppSettings')));
const Landing = React.lazy(() => import('./Views/Landing/Landing'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <ThemeProvider theme={theme}>
    <BrowserRouter>
    <GlobalProviders>
      <React.Suspense>
        <Routes>
          <Route path='/' element={<Landing/>} />
          <Route path='/auth' element={<Auth />} />
          <Route path='/app/*' element={<App />} />
          <Route path='/settings/*' element={<AppSettings />} />
          <Route path='*' element={<div>Not Found.</div>} />
        </Routes>
      </React.Suspense>
    </GlobalProviders>
    </BrowserRouter>
    </ThemeProvider>
);

reportWebVitals(console.log);
