import { useAuth0 as useAuth} from '@auth0/auth0-react';
import { Box, Button } from '@mui/material';
import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';


function Auth(){
  const [urlSearchParams, setURLSearchParams] = useSearchParams();
  const { user, isAuthenticated, isLoading, logout } = useAuth();
  if (isAuthenticated) {
    return <Navigate replace to="/app"/>
  }
  return(<Box>
    <Box>User Authenticated: {isAuthenticated? 'True' : "False"}</Box>
    <Box>Error: {urlSearchParams.get('error')} </Box>
    <Box>Message: {urlSearchParams.get('error_description')} </Box>
    <Button onClick={()=>logout()}>Cerrar sesión</Button>
    </Box>)
}
export default Auth;