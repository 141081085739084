declare module '@mui/material/styles' {
  interface Palette {
    customGrey: Palette['primary'];
  }
  interface PaletteOptions {
    customGrey: PaletteOptions['primary'];
  }
}

const palette = {
    primary:{
      main: '#235CE8',  
    },
    secondary:{
      main: '#001044',
    },
    success: {
      main: '#1DCC89',
    },
    warning: {
      main: '#E88C1B',
    },
    customGrey: {
      main:'#F9F9FA',
    },
    text:{
      primary:'#67757c',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    contrastThreshold: 4.5,
}

export default palette;